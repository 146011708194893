<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in primaryPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'trustee_primary'"
      >
      </Person>
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :person="selectedPerson"
        :show="show.personModal"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'

export default {
  name: 'TrusteesPrimary',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad
  },
  computed: {
    heading() {
      return 'Who would you like to name as the main Trustee(s)?'
    },
    number() {
      if (this.partner && !this.partner.trustee_sole_primary) return '3'
      return '2'
    },
    subHeading() {
      return '  These people will look after the inheritance of any younger beneficiaries. People often appoint two or more Trustees to work together, although you could appoint just one person if you so wish.'
    },
    primaryPeople() {
      return this.$store.getters.people.filter(
        (person) =>
          !person.trustee_secondary &&
          !person.under18 &&
          !person.trustee_backup &&
          !person.trsutee_sole_primary
      )
    },
    people() {
      return this.$store.getters.people
    },
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    forwardTo() {
      if (this.partner && !this.partner.trustee_sole_primary)
        return '/trustees/secondary'
      else if (this.partner && this.partner.trustee_sole_primary)
        return '/trustees/check_and_confirm'
      else if (!this.partner) return '/trustees/secondary_option'
      return '/trustees/secondary_option'
    },
    backTo() {
      if (this.partner) return '/trustees/partner'
      return '/trustees/executors_as_trustees'
    },
    isValidated() {
      if (this.people.filter((person) => person.trustee_primary).length)
        return true
      return false
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      let personCopy = Object.assign({}, person)
      personCopy.trustee_primary = !personCopy.trustee_primary
      this.$store.commit('personAdd', personCopy)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('trustee_primary')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
